<template>
  <div id="clientInfo">
    <div id="panel-header">
      <span class="top-title">登录日志列表</span>
    </div>
    <div id="filter-bar">
      <Card dis-hover>
        <Form :model="formItem" ref="query" inline>
          <FormItem prop="type">
            <Select @on-change="searchSubmit" v-model="formItem.type" class="w-200" placeholder="请选择登录类型">
              <Option v-for="item in type" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>

          <FormItem prop="created_at">
            <DatePicker class="w-200" type="daterange" @on-change="timeChange" v-model="formItem.created_at" placement="bottom-end"
                        placeholder="请选择登录时间"></DatePicker>
          </FormItem>

          <FormItem>
            <Button type="text" class="main-font-color" @click="cancelSearch('query')">清除</Button>
          </FormItem>
        </Form>
      </Card>
    </div>
    <div id="login_log_list">
      <Table :columns="columnsInfo" :data="listInfo">

        <template slot-scope="{ row }" slot="type">
          <span>{{ getTypeName(row.type) }}</span>
        </template>

      </Table>
      <div class="page">
        <Page :total="listTotal" :page-size="pageSize" show-total show-elevator @on-change="changePage"/>
      </div>

    </div>

  </div>
</template>

<script>
import '@/css/common.css';

export default {
  name: "LogList",
  data() {
    return {
      formItem: {
        type: '',
        created_at: '',
      },
      type: [
        {
          "id": 1,
          "name": '登录'
        },
        {
          "id": 2,
          "name": '退出'
        }
      ],

      columnsInfo: [

        {
          title: '登录类型',
          key: 'type',
          slot: 'type',
          align: 'center',
        },
        {
          title: '登录用户',
          key: 'operator_name',
          align: 'center',
        },
        {
          title: 'ip地址',
          key: 'ip',
          align: 'center',
        },
        // {
        //   title: '所在城市',
        //   key: 'city',
        //   align: 'center',
        // },
        {
          title: '创建时间',
          key: 'created_at',
          align: 'center',
        },

      ],
      listInfo: [],

      listTotal: 0,
      pageSize: 0,
      searchFlag: 0,

    };
  },
  methods: {
    getLogList($page = 1) {
      let param = {
        params: {
          page: $page,
          type: this.formItem.type,
          created_at: this.formItem.created_at,
        }
      };

      this.$axios.get('/api/user/getLoginLogList', param).then((response) => {
        if (response.data.err_no == 0) {
          this.listInfo = response.data.results.list;
          this.listTotal = response.data.results.total;
          this.pageSize = response.data.results.size;
        }
      });
    },

    getTypeName(typeId) {
      let typeName;
      let type = this.type;
      for (let item in type) {
        if (typeId == type[item].id) {
          typeName = type[item].name;
          break;
        }
      }
      return typeName;

    },
    cancelSearch(name) {
      this.searchFlag = 0;
      this.$refs[name].resetFields();
      this.getLogList();
    },
    searchSubmit() {
      this.searchFlag = 1;
      this.getLogList();
    },
    timeChange(dateRange) {
      if (dateRange[0] && dateRange[1]) {
        this.formItem.created_at = dateRange;
      } else {
        this.formItem.created_at = "";
      }

      this.searchSubmit();
    },
    changePage(page) {
      this.getLogList(page);
    },

  },
  mounted() {
    this.getLogList();
  },
};
</script>

<style scoped>
#clientInfo {
  padding: 23px 40px;
  min-width: 960px;
  min-height: calc(100vh - 50px);
}

#panel-header {
  position: relative;
  border: none;
}

.top-title {
  color: #333;
  font-size: 20px;
  line-height: 18px;
  cursor: pointer;
  font-weight: 600;
}

#filter-bar {
  margin-top: 18px;
  font-size: 12px !important;
}

#login_log_list {
  margin-top: 12px;
  margin-bottom: 40px;
}

#login_log_list .page {
  margin-top: 16px;
}
</style>
<style>
#clientInfo .ivu-card {
  background-color: #fafafa;
}

#clientInfo .ivu-card-bordered {
  border: 1px solid #f5f5f5;
}

#clientInfo .ivu-form-item {
  margin-bottom: 0;
}

#login_log_list .ivu-table-wrapper {
  border: 1px solid #f4f4f4;
  border-bottom: 0;
  border-radius: 6px;
}

#login_log_list.ivu-table td, .ivu-table th {
  border-bottom: 1px solid #f4f4f4;
}

#login_log_list .ivu-page {
  float: right;
}

#login_log_list .ivu-table-overflowX, #login_log_list .ivu-table-tip {
  overflow-x: hidden;
}

#login_log_list .ivu-table-wrapper {
  overflow: visible;
}
</style>





