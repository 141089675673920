<template>
    <div id="my-info">
        <div id="panel-header">
            <span class="top-title">我的信息</span>
        </div>
        <div id="filter-bar">
            <Card style="width:66%" dis-hover>
                <Form :label-width="80" inline label-colon>
                    <Row class="mt-20">
                        <Col span="11">
                        <FormItem label="名称" >
                            <span class="fs-26 fw-300">{{user.name}}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span  @click="editName('clientFormItem',user.name)" class="pointer fs12 main-color"><Icon type="ios-create" />修改</span>
                        </FormItem>
                        </Col>
                    </Row>
<!--                    <Row class="mt-20">-->
<!--                      <Col span="11">-->
<!--                        <FormItem label="头像" >-->
<!--                          <Avatar v-show="user.avatar" :src="user.avatar"/>-->
<!--                        </FormItem>-->
<!--                      </Col>-->
<!--                    </Row>-->
                    <Row class="mt-20">
                      <Col span="11">
                        <FormItem label="手机" >
                          <span>{{user.phone}}</span>&nbsp;&nbsp;
                          <!--<span  @click="editPhone('clientFormItem')"><Icon type="ios-create" />修改</span>-->
                        </FormItem>
                      </Col>
                    </Row>
                    <Row class="mt-20">
                      <Col span="11">
                        <FormItem label="密码" >
                          <span>********</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span  @click="editPassword('pass_data')" class="pointer fs12 main-color"><Icon type="ios-create" />修改</span>
                        </FormItem>
                      </Col>
                    </Row>
                </Form>
            </Card>
        </div>

        <Modal v-model="edit.modal" :title="edit.model_title" width="800" >
            <div class="modalForm">
                <Form ref="clientFormItem" :model="clientFormItem" :label-width="100" :rules="ruleInline" label-colon>
                    <Row>
                        <Col span="11" v-show="edit.editNameFlag">
                        <FormItem label="名称" prop="name">
                            <Input v-model="clientFormItem.name" placeholder="名称"></Input>
                        </FormItem>
                        </Col>

                        <Col span="11" v-show="edit.editPhoneFlag">
                        <FormItem label="手机" prop="phone">
                            <Input v-model="clientFormItem.phone" placeholder="手机"></Input>
                        </FormItem>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div slot="footer">
                <Button @click="handleReset('clientFormItem')">取消</Button>
                <Button type="primary" :loading="edit.modal_loading" @click="editSubmit('clientFormItem')">保存</Button>
            </div>

        </Modal>

      <Modal v-model="pass.modal" :title="pass.model_title" width="800" >
        <div class="modalForm">
          <Form ref="pass_data" :model="pass.data" :label-width="100" :rules="ruleInline" label-colon>
            <Row>
              <Col span="11">
                <FormItem label="旧密码" prop="old_password">
                  <Input type="password" v-model="pass.data.old_password" placeholder="请输入旧密码"></Input>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="11">
                <FormItem label="新密码" prop="new_password">
                  <Input type="password" v-model="pass.data.new_password" placeholder="请输入新密码"></Input>
                </FormItem>
              </Col>
            </Row>
            <Row>
              <Col span="11">
                <FormItem label="重复新密码" prop="repeated_password">
                  <Input type="password" v-model="pass.data.repeated_password" placeholder="请再一次输入新密码"></Input>
                </FormItem>
              </Col>
            </Row>
          </Form>
        </div>
        <div slot="footer">
          <Button @click="handlePassReset('pass_data')">取消</Button>
          <Button type="primary" :loading="pass.modal_loading" @click="editPassSubmit('pass_data')">保存</Button>
        </div>

      </Modal>

    </div>
</template>

<script>
import {
  editUserPassword,
} from '@/api/user/myInfo';

    export default {
        name: "MyInfo",
        data() {
            return {
                user: {
                    name: '',
                    avatar: '',
                    phone: '',

                },

                clientFormItem: {
                    name:'',
                    phone:'',
                    avatar:'',
                    parent_id:'',
                },
                edit: {
                  modal: false,
                  model_title: '修改信息',
                  modal_loading: false,
                  editNameFlag:false,
                  editPhoneFlag:false,
                },
                pass: {
                  data: {
                    old_password: '',
                    new_password: '',
                    repeated_password: '',
                  },
                  modal: false,
                  model_title: '修改信息',
                  modal_loading: false,
                },
                ruleInline: {
                  name: [
                      {required: true, message: '请填写名称', trigger: 'blur'}
                  ],
                  old_password: [
                    {required: true, message: '请填写旧密码', trigger: 'blur'}
                  ],
                  new_password: [
                    {required: true, message: '请填写新密码', trigger: 'blur'},
                    {type: 'string', min: 6, message: '密码长度不能小于6位', trigger: 'blur'}
                  ],
                  repeated_password: [
                    {required: true, message: '请填写重复新密码', trigger: 'blur'}
                  ],
                },

            };
        },
        methods: {
          editPassword(name) {
            this.$refs[name].resetFields();
            this.pass.modal_loading = false;
            this.pass.modal = true;
          },
          editName(formName, userName) {
              this.edit.modal_loading = false;
              this.edit.modal = true;
              this.edit.editNameFlag = true;
              this.edit.editPhoneFlag = false;

              this.$refs[formName].resetFields();
              this.$axios.get('/api/user/userInfo')
                  .then((response) => {
                      this.clientFormItem = response.data.results.userInfo;
                  });
          },
          editPhone(formName, userPhone) {
              this.edit.modal_loading = false;
              this.edit.modal = true;
              this.edit.editPhoneFlag = true;
              this.edit.editNameFlag = false;

              this.$refs[formName].resetFields();
              this.$axios.get('/api/user/userInfo')
                  .then((response) => {
                    this.clientFormItem = response.data.results.userInfo;
                  });
          },
          handleReset(name){
              this.$refs[name].resetFields();

              this.edit.editNameFlag = false;
              this.edit.editPhoneFlag = false;
              //弹出框重置
              this.edit.modal = false;
              this.edit.modal_loading = false;
          },
          handlePassReset(name) {
            this.$refs[name].resetFields();
            this.pass.modal = false;
            this.pass.modal_loading = false;
          },
          editSubmit(name) {
              this.edit.modal_loading = true;
              this.$refs[name].validate((valid) => {
                  if (valid) {
                    this.$axios.post('/api/user/editUser', this.clientFormItem)
                          .then((response) => {
                              if (response.data.err_no == 0) {
                                  this.$Message.success('保存成功');
                                  this.edit.modal = false;
                                  this.getUserDetail();
                              } else {
                                  this.$Message.error(response.data.err_msg);
                              }
                              this.edit.modal_loading = false;
                          });
                  } else {
                      this.edit.modal_loading = false;
                  }
              });
          },
          editPassSubmit(name) {
            this.pass.modal_loading = true;
            let param = {
              old_password: this.md5(this.pass.data.old_password),
              new_password: this.md5(this.pass.data.new_password),
              repeated_password: this.md5(this.pass.data.repeated_password),
            };
            this.$refs[name].validate((valid) => {
              if (valid) {
                if (param.new_password != param.repeated_password) {
                  this.pass.modal_loading = false;
                  this.$Message.error('新设置密码，两次不一样，请留意！');
                  return;
                }

                if (param.new_password == param.old_password) {
                  this.pass.modal_loading = false;
                  this.$Message.error('新旧密码不能一样，请留意！');
                  return;
                }

                editUserPassword(param).then((response) => {
                  if (response.data.err_no == 0) {
                    this.$Message.success('保存成功');
                    this.pass.modal = false;
                    this.getUserDetail();
                  } else {
                    this.$Message.error(response.data.err_msg);
                  }
                  this.pass.modal_loading = false;
                }).catch(err => {
                  this.pass.modal_loading = false;
                  this.$Message.error('操作异常！');
                });
              } else {
                this.pass.modal_loading = false;
              }
            });
          },


          getUserDetail() {
              this.$axios.get('/api/user/userInfo').then((response) => {
                  if (response.data.err_no == 0) {
                      this.user = response.data.results.userInfo;
                  }
              })
          },
        },
        mounted() {
          this.getUserDetail();
        },
    };
</script>

<style scoped>
    #my-info {
        padding: 23px 40px;
        min-width: 960px;
        min-height: calc(100vh - 50px);
    }

    #panel-header {
        position: relative;
        border: none;
    }

    .modalForm {
        width: 100%;
    }

    .top-title {
        color: #333;
        font-size: 20px;
        line-height: 18px;
        cursor: pointer;
        font-weight: 600;
    }

    .client_create {
        position: absolute;
        right: 0;
        top: -8px;
    }

    #filter-bar {
        margin-top: 18px;
        font-size: 12px !important;
    }

    #list {
        margin-top: 12px;
        margin-bottom: 40px;
    }

    #list .page {
        margin-top: 16px;
    }
</style>
<style>
    #my-info .ivu-card {
        background-color: #fafafa;
    }

    #my-info .ivu-card-bordered {
        border: 1px solid #f5f5f5;
    }

    #my-info .ivu-form-item-content {
        width: 200px;
    }

    #my-info .ivu-form-item {
        margin-bottom: 0;
    }

    /*#my-info .ivu-form .ivu-form-item-label {*/
    /*    font-size: 12px !important;*/
    /*}*/

    #my-info .ivu-btn-small {
        font-size: 12px !important;
    }

    #my-info .ivu-input, #my-info .ivu-select-input {
        font-size: 12px !important;
    }

    #my-info .ivu-select-small.ivu-select-single .ivu-select-selection .ivu-select-placeholder, .ivu-select-small.ivu-select-single .ivu-select-selection .ivu-select-selected-value {
        font-size: 12px !important;
    }

    #list .ivu-table-wrapper {
        border: 1px solid #f4f4f4;
        border-bottom: 0;
        border-radius: 6px;
    }

    #list.ivu-table td, .ivu-table th {
        border-bottom: 1px solid #f4f4f4;
    }

    #list .ivu-page {
        float: right;
    }
</style>
