<template id="menu">
    <Menu theme="light" :active-name="active_name" @on-select="onselect" width="auto">
        <MenuGroup title="账号信息">
            <MenuItem name="4">
                <Icon type="ios-list-box-outline" />
                账号信息
            </MenuItem>
            <!-- <MenuItem name="6" v-show="is_top">
              <Icon type="logo-yen" />
              费用信息
            </MenuItem> -->
        </MenuGroup>
        <MenuGroup title="个人信息">
          <MenuItem name="1">
              <Icon type="ios-contact" />
              我的信息
          </MenuItem>
          <MenuItem name="2">
              <Icon type="md-grid" />
              操作日志
          </MenuItem>
          <MenuItem name="3">
              <Icon type="md-grid" />
              登录日志
          </MenuItem>
<!--          <MenuItem name="5">-->
<!--            <Icon type="ios-download" />-->
<!--            导出日志-->
<!--          </MenuItem>-->
        </MenuGroup>
    </Menu>
</template>

<script>
import * as loginUtil from '@/utils/login'

	export default {
		name: "SettingMenu",
		data() {
			return {
				active_name:this.active,
        is_top: false,
			};
		},
		methods: {
			onselect(name) {
				this.$emit('func',name);
        loginUtil.setExpireTime();
      }
		},
    mounted() {
      let user_info = JSON.parse(localStorage.getItem('user_info'));
      this.is_top = user_info.base_id != undefined && user_info.base_id==0 ? true : false;
    },

		props:["active"],
	};
</script>

<style scoped>
    #menu {
        width: 200px;
        position: absolute;
        left:0;
    }
</style>
